import { useState, useContext, useEffect } from "react";
import { FaEdit, FaGlobe, FaPlusCircle, FaTrashAlt, FaUser } from "react-icons/fa";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Modal from "components/ui/modal/modal";
import { AppContext } from "components/app/RemExprertProvider";
import Dialogs from "components/ui/dialogs/dialogs";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import { RemExpertResultatSalarie } from "components/app/Api/ResultatRemExpertSalarieProvider";
import OptiRemPage from "./optiRem";
import Pager from "components/ui/controls/pager/pager";

const SimulationPage = (props) => {
  const context = useContext(AppContext);
  const [clientConjoint, setClientConjoint] = useState("");
  const [modalOpen, setmodalOpen] = useState(false);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const ResultatSalarie = useContext(RemExpertResultatSalarie);
  const Periode = context.state["PeriodeActuelle"][0];
  const PeriodeConjoint = context.state["PeriodeActuelleConjoint"][0];
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    
    if (Periode !== undefined) {
      if (Periode?.Statut === "TNS Article 62" || Periode?.Statut === "TNS individuel") {
        ResultatTNS.GetResultRemExpert(context.state, "").then(() => {
        });
      } else {
        ResultatSalarie.GetResultRemExpert(context.state, "").then(() => {
        });
      }

      if (context.state.InclureConjoint === true && Periode?.StatutConjoint !== "Aucun") {
        if (Periode?.StatutConjoint === "TNS Article 62" || Periode?.StatutConjoint === "TNS individuel") {
          ResultatTNS.GetResultRemExpert(context.state, "ConjointAssocie").then(() => {
          });
        } else {
          ResultatSalarie.GetResultRemExpert(context.state, "ConjointAssocie").then(() => {
          });
        }
      }
    }
    if (PeriodeConjoint !== undefined) {
      if (PeriodeConjoint?.Statut === "TNS Article 62" || PeriodeConjoint?.Statut === "TNS individuel") {
        ResultatTNS.GetResultRemExpert(context.state, "Conjoint").then(() => {
        });
      } else {
        ResultatSalarie.GetResultRemExpert(context.state, "Conjoint").then(() => {
        });
      }
    }

    context.handleOpenRemiseDonneeOptirem("", ResultatTNS, ResultatSalarie).then(() => {
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   context.GetDateDepartRetraite("", context.state);
  //   if (context.state.CarriereConjoint.length > 0) {
  //     context.GetDateDepartRetraite("Conjoint", context.state);
  //   }

  // }, []);
  return (
    <>
      <Column fill>
        <Row>
          <Column fill>
            <CardBox bpad>
              <Column fill>
                <Row>
                  <h1>Liste des simulations réalisées</h1>
                </Row>
                <Row center>
                  <Column style={{ paddingLeft: 0 }}>
                    <Button
                      onClick={() => {
                        // setClientConjoint("");
                        setCurrentPage(0);
                        context.handleResetOptimisation();
                        context.handleOpenRemiseDonneeOptirem("", ResultatTNS, ResultatSalarie);
                        setmodalOpen(true);
                      }}
                    >
                      <span>Ajouter une simulation</span>
                      <FaPlusCircle />
                    </Button>
                  </Column>
                </Row>
                <Row tpad>
                  <table>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Libellé</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {context.state.ListeOptimisation.map((Infos, Index) => {
                        var date = new Date(Infos.DateAdd);
                        var year = date.getFullYear();
                        var month = (1 + date.getMonth()).toString();
                        month = month.length > 1 ? month : "0" + month;
                        var day = date.getDate().toString();
                        day = day.length > 1 ? day : "0" + day;
                        date = day + "/" + month + "/" + year;

                        return (
                          <tr key={Index}>
                            <td>{date}</td>
                            <td>{Infos.LibelleOptimisation}</td>
                            <td>
                              <Button
                                onClick={() => {
                                  // setClientConjoint("");
                                  context.handleAccesOptimisation(Infos.IdOptimisation, ResultatTNS);
                                  setmodalOpen(true);
                                  // context.handleModifyDeletePeriodeRetraite(Infos.id, "Modifier", "", "");
                                  // setmodalPeriodeRetr(true);
                                }}
                                icon
                                style={{ display: "inline-block" }}
                              >
                                <FaEdit />
                              </Button>
                              <Button
                                onClick={() => {
                                  // setClientConjoint("");
                                  Dialogs.confirm("Vous êtes sur le point de supprimer la simulation.\nVoulez-vous continuer ?", "Suppression d'une simulation", () => {
                                    context.handleDeleteOptimisation(Infos.IdOptimisation);
                                  });
                                }}
                                icon
                                style={{ display: "inline-block" }}
                              >
                                <FaTrashAlt className="delete" />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Row>
              </Column>
            </CardBox>
          </Column>
        </Row>
      </Column>

      <Modal
        width="90%"
        height="90%"
        title="Ajouter une simulation"
        icon={<FaGlobe />}
        visible={modalOpen}
        onClose={() => {
          setmodalOpen(false);
        }}
      >
        {PeriodeConjoint !== undefined ? (
          <Pager
            withMenu
            pages={[
              {
                label: context.state.Prenom,
                icon: <FaUser />,
                content: (
                  <OptiRemPage
                    ClientConjoint={""}
                    visible={modalOpen}
                    onReset={(page) => {
                      setCurrentPage(0);
                    }}
                    onClose={() => {
                      setmodalOpen(false);
                    }}
                  />
                ),
              },
              {
                label: context.state.PrenomConjoint,
                icon: <FaUser />,
                content: (
                  <OptiRemPage
                    ClientConjoint={"Conjoint"}
                    visible={modalOpen}
                    onReset={(page) => {
                      setCurrentPage(0);
                    }}
                    onClose={() => {
                      setmodalOpen(false);
                    }}
                  />
                ),
              },
            ]}
            page={currentPage}
            onPageChange={(page) => {
              setCurrentPage(page);
            }}
          />
        ) : (
          <OptiRemPage
            ClientConjoint={""}
            visible={modalOpen}
            onReset={(page) => {
              setCurrentPage(0);
            }}
            onClose={() => {
              setmodalOpen(false);
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default SimulationPage;
