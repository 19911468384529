import React, { useEffect, useState } from "react";
import { pdf } from "@react-pdf/renderer";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Invoice from "./client/invoice/components/reports/Invoice";
import CardBox from "components/ui/controls/card-box/card-box";
import { User } from "components/app/Api/ApiUser";
import { FaExclamationTriangle, FaFilePdf } from "react-icons/fa";
import { saveAs } from 'file-saver';


const generatePdfDocument = async (documentData) => {
  console.log(documentData)
  const blob = await pdf((
    <Invoice invoice={documentData} />
  )).toBlob();
  saveAs(blob, documentData.number+' '+documentData.date );
};

const InvoicesPage = (props) => {
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
   
    User({}, "GetInvoices").then((Invoices) => {
      setInvoices(Invoices);
    });
  }, []);

  return (
    <Column scroll fill>
      <CardBox>
        <Column fill>
          <Row>
            <h1>Factures</h1>
          </Row>
          <Row tpad>
            <Column fill>i
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Statut</th>
                    <th>Lien</th>
                  </tr>
                </thead>
                <tbody>
                  {invoices.map((Infos, index) => (
                    <tr key={"invoices" + index}>
                      <td>{Infos.date}</td>
                      <td>{!Infos.paid_invoice_pdf ? "En attente de paiement" : "Payée"}</td>
                      {Infos.number.substr(0, 1) === "F" && (
                        <td>
                          {!Infos.paid_invoice_pdf ? (
                            <FaExclamationTriangle style={{ color: "red" }} />
                          ) : (
                            <a style={{ color: "var(--app-selected-bg-color)" }} target="_blank" href={Infos.customer_portal_url}>
                              <FaFilePdf></FaFilePdf>
                            </a>
                          )}
                        </td>
                      )}
                      {Infos.number.substr(0, 1) !== "F" && (
                        <td>
                          {!Infos.paid_invoice_pdf  ? (
                            <FaExclamationTriangle style={{ color: "red" }} />
                          ) : (
                          
                            <FaFilePdf
                            style={{ color: "var(--app-selected-bg-color)" }}
                            onClick={()=>{
                              generatePdfDocument(Infos)
                            }}
                            />
                            // <InvoicesLink InvoiceInfo={Infos}/>
                            // <a style={{ color: "var(--app-selected-bg-color)" }} href={Infos.paid_invoice_pdf}>
                            //   <FaFilePdf></FaFilePdf>
                            // </a>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Column>
          </Row>
        </Column>
      </CardBox>
    </Column>
  );
};

export default InvoicesPage;
