import React, { useState } from "react";
import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import { FaGlobe } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import Column from "components/ui/layout/column/column";
import CardBox from "components/ui/controls/card-box/card-box";
import Row from "components/ui/layout/row/row";
import Modal from "components/ui/modal/modal";
import DropDown from "components/ui/controls/drop-down/drop-down";

const PointCarcdsfAsv = (props) => {
  const { resultRetraite, ClientConjoint } = props;
  const context = useContext(AppContext);

  let [openModal, setOpenModal] = useState(false);
  const InitialAnneeAcquisition = () => {
    const dt = new Date();

    return dt.getFullYear() - 2;
  };
  return (
    <>
      {context.state["Carriere" + ClientConjoint].filter((Carriere) => Carriere.Nature === "Carcdsf" && Carriere.ParamCotisations.Conventionne === "oui").length > 0 && (
        <tr>
          <td>PCV CARCD</td>
          <td
            onClick={() => {
              setOpenModal(true);
            }}
          >
            <TextField
              value={context.state["TableauPoints" + ClientConjoint].RetraiteSuppCarcdsf}
              style={{ backgroundColor: "white" }}
              disabled
              dataType="ufloat"
              format={{ thousands: " ", precision: 2, decimal: "," }}
              small
            />
          </td>
          <td>
            <DropDown
              value={context.state["TableauPoints" + ClientConjoint].AnneeFinCarcdsfSupp}
              onChange={(e) => {
                context.handlePointRetraite(ClientConjoint, "AnneeFinCarcdsfSupp", e);
                props.CalculRetraite();
              }}
              options={[
                { value: InitialAnneeAcquisition(), label: InitialAnneeAcquisition() },
                { value: InitialAnneeAcquisition() + 1, label: InitialAnneeAcquisition() + 1 },
              ]}
              small
            />
          </td>
          <td>
            <TextField
              value={resultRetraite?.InfosRetraite?.Carcdsf?.General?.AsvPointCalcule === undefined ? 0 : resultRetraite?.InfosRetraite?.Carcdsf?.General?.AsvPointCalcule}
              disabled={true}
              dataType="ufloat"
              format={{ thousands: " ", precision: 2, decimal: "," }}
              small
            />
          </td>
        </tr>
      )}

      <Modal
        width="30%"
        height="30%"
        title=" POINTS PCV CARCD"
        icon={<FaGlobe />}
        visible={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <Pager
          pages={[
            {
              content: (
                <Column fill>
                  <CardBox>
                    <Column fill>
                      <Row>
                        <Column>
                          <TextField
                            label="Avant 1995"
                            value={context.state["TableauPoints" + ClientConjoint].RetraiteSuppCarcdsfAvant1995}
                            onChange={context.handleModalPoint(ClientConjoint, "RetraiteSuppCarcdsfAvant1995")}
                            dataType="ufloat"
                            format={{ thousands: " ", precision: 2, decimal: "," }}
                            normal
                          />
                        </Column>
                      </Row>
                      <Row>
                        <Column>
                          <TextField
                            label="Entre 1995 et 2005"
                            value={context.state["TableauPoints" + ClientConjoint].RetraiteSuppCarcdsf19952005}
                            onChange={context.handleModalPoint(ClientConjoint, "RetraiteSuppCarcdsf19952005")}
                            dataType="ufloat"
                            format={{ thousands: " ", precision: 2, decimal: "," }}
                            normal
                          />
                        </Column>
                      </Row>
                      <Row>
                        <Column>
                          <TextField
                            label={"Entre 2006 et " + context.state.TableauPoints.AnneeFinCarcdsfSupp}
                            value={context.state["TableauPoints" + ClientConjoint].RetraiteSuppCarcdsfApres2006}
                            onChange={context.handleModalPoint(ClientConjoint, "RetraiteSuppCarcdsfApres2006")}
                            dataType="ufloat"
                            format={{ thousands: " ", precision: 2, decimal: "," }}
                            normal
                          />
                        </Column>
                      </Row>
                    </Column>
                  </CardBox>
                </Column>
              ),
            },
          ]}
          page={0}
          navButtons={{
            validate: {
              onClick: (evt) => {
                setOpenModal(false);
              },
            },
          }}
        />
      </Modal>
    </>
  );
};
export default PointCarcdsfAsv;
