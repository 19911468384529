import React, { useState } from "react";
import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import { FaGlobe } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import Column from "components/ui/layout/column/column";
import CardBox from "components/ui/controls/card-box/card-box";
import Row from "components/ui/layout/row/row";
import Modal from "components/ui/modal/modal";
import DropDown from "components/ui/controls/drop-down/drop-down";

const PointRci = (props) => {
  const { resultRetraite, ClientConjoint } = props;
  const context = useContext(AppContext);
  const InitialAnneeAcquisition = () => {
    const dt = new Date();

    return dt.getFullYear() - 2;
  };
  let [openModal, setOpenModal] = useState(false);
  return (
    <>
      {context.state["Carriere" + ClientConjoint].filter(
        (Carriere) =>
          Carriere.Nature === "RsiCommercant" || Carriere.Nature === "RsiArtisan" || Carriere.Nature === "MicroRSI" || Carriere.Nature === "MicroBIC" || Carriere.Nature === "MicroBNC"
      ).length > 0 && (
        <tr>
          <td>RCI</td>
          <td
            onClick={() => {
              setOpenModal(true);
            }}
          >
            <TextField
              value={context.state["TableauPoints" + ClientConjoint].RetraiteTotalRCI}
              style={{ backgroundColor: "white" }}
              disabled
              dataType="ufloat"
              format={{ thousands: " ", precision: 2, decimal: "," }}
              small
            />
          </td>
          <td>
            <DropDown
              value={context.state["TableauPoints" + ClientConjoint].AnneeFinRsiComp}
              onChange={(e) => {
                context.handlePointRetraite(ClientConjoint, "AnneeFinRsiComp", e);
                props.CalculRetraite();
              }}
              options={[
                { value: InitialAnneeAcquisition(), label: InitialAnneeAcquisition() },
                { value: InitialAnneeAcquisition() + 1, label: InitialAnneeAcquisition() + 1 },
              ]}
              small
            />
          </td>
          <td>
            <TextField
              value={resultRetraite?.InfosRetraite?.RCI?.General?.PointCalcule === undefined ? 0 : resultRetraite?.InfosRetraite?.RCI?.General?.PointCalcule}
              disabled={true}
              dataType="ufloat"
              format={{ thousands: " ", precision: 2, decimal: "," }}
              small
            />
          </td>
        </tr>
      )}

      <Modal
        width="30%"
        height="56%"
        title="POINTS RSI"
        icon={<FaGlobe />}
        visible={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <Pager
          pages={[
            {
              content: (
                <Column fill>
                  <CardBox>
                    <Column fill>
                      {context.state["Carriere" + ClientConjoint].filter((Carriere) => Carriere.Nature === "RsiArtisan").length > 0 && (
                        <>
                          <Row>
                            <h1>RSI ARTISAN</h1>
                          </Row>
                          <Row>
                            <Column>
                              <TextField
                                label="Avant 1979"
                                value={context.state["TableauPoints" + ClientConjoint].RetraiteCompRsiArtisanAvant1979}
                                onChange={context.handleModalPoint(ClientConjoint, "RetraiteCompRsiArtisanAvant1979")}
                                dataType="ufloat"
                                format={{ thousands: " ", precision: 2, decimal: "," }}
                                normal
                              />
                            </Column>
                          </Row>
                          <Row>
                            <Column>
                              <TextField
                                label="Avant 1997"
                                value={context.state["TableauPoints" + ClientConjoint].RetraiteCompRsiArtisanAvant1997}
                                onChange={context.handleModalPoint(ClientConjoint, "RetraiteCompRsiArtisanAvant1997")}
                                dataType="ufloat"
                                format={{ thousands: " ", precision: 2, decimal: "," }}
                                normal
                              />
                            </Column>
                          </Row>
                          <Row>
                            <Column>
                              <TextField
                                label="Entre 1997 et 2012"
                                value={context.state["TableauPoints" + ClientConjoint].RetraiteCompRsiArtisanApres1997}
                                onChange={context.handleModalPoint(ClientConjoint, "RetraiteCompRsiArtisanApres1997")}
                                dataType="ufloat"
                                format={{ thousands: " ", precision: 2, decimal: "," }}
                                normal
                              />
                            </Column>
                          </Row>
                        </>
                      )}
                      {context.state["Carriere" + ClientConjoint].filter(
                        (Carriere) => Carriere.Nature === "RsiCommercant" || Carriere.Nature === "MicroRSI" || Carriere.Nature === "MicroBIC" || Carriere.Nature === "MicroBNC"
                      ).length > 0 && (
                        <>
                          <Row tpad>
                            <h1>RSI COMMERCANT</h1>
                          </Row>
                          <Row>
                            <Column>
                              <TextField
                                label="Avant 2013"
                                value={context.state["TableauPoints" + ClientConjoint].PointRetraiteCompRsiCommercantAvant2013}
                                onChange={context.handleModalPoint(ClientConjoint, "PointRetraiteCompRsiCommercantAvant2013")}
                                dataType="ufloat"
                                format={{ thousands: " ", precision: 2, decimal: "," }}
                                normal
                              />
                            </Column>
                          </Row>
                        </>
                      )}
                      <>
                        <Row tpad>
                          <h1>RCI</h1>
                        </Row>
                        <Row>
                          <Column>
                            <TextField
                              label={"Entre 2013 et " + context.state.TableauPoints.AnneeFinRsiComp}
                              value={context.state["TableauPoints" + ClientConjoint].RetraiteCompRCI}
                              onChange={context.handleModalPoint(ClientConjoint, "RetraiteCompRCI")}
                              dataType="ufloat"
                              format={{ thousands: " ", precision: 2, decimal: "," }}
                              normal
                            />
                          </Column>
                        </Row>
                      </>
                    </Column>
                  </CardBox>
                </Column>
              ),
            },
          ]}
          page={0}
          navButtons={{
            validate: {
              onClick: (evt) => {
                setOpenModal(false);
              },
            },
          }}
        />
      </Modal>
    </>
  );
};
export default PointRci;
