import React from "react";
import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import DropDown from "components/ui/controls/drop-down/drop-down";
import CheckBox from "components/ui/controls/check-box/check-box";
import TextField from "components/ui/controls/text-field/text-field";
import Button from "components/ui/controls/button/button";
import StateBox from "components/ui/controls/state-box/state-box";
import { FaExclamationTriangle, FaInfo } from "react-icons/fa";
import Hover from "components/ui/controls/hover/hover";

const DateDepartRetraite = (props) => {
  const { ClientConjoint } = props;
  const context = useContext(AppContext);
  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row  tpad>
              {/* <Column >
                <CheckBox
                  label="Application de la réforme des retraites 2023 (hors départ anticipé carrière longue)"
                  inline
                  checked={true} 
                  unchecked={false}
                  value={context.state["ReformeRetraite" + ClientConjoint]}
                  onChange={context.handleReformeRetraite(ClientConjoint, "ReformeRetraite"+ClientConjoint)}
                />
              </Column> */}
            </Row>
            <Row tpad>
             
              <Column>
                <StateBox
                  label="A l'âge légal"
                  inline
                  value={context.state["DepartAgeLegal" + ClientConjoint]}
                  onClick={context.handleDepartRetraite(ClientConjoint, "DepartAgeLegal")}
                  normal
                />
              </Column>
            </Row>
            <Row tpad>
              <Column>
                <StateBox
                  label="A l'âge de"
                  inline
                  value={context.state["DepartAgeChoisi" + ClientConjoint]}
                  onClick={context.handleDepartRetraite(ClientConjoint, "DepartAgeChoisi")}
                  normal
                />
              </Column>
              <Column>
                <TextField
                  value={context.state["DepartAgeChoisiSelect" + ClientConjoint]}
                  onChange={context.handleDepartRetraite(ClientConjoint, "DepartAgeChoisiSelect")}
                  xs
                  textAlign="center"
                />
              </Column>
              <Column style={{ alignSelf: "center" }}>ans et</Column>
              <Column>
                <TextField
                  value={context.state["DepartMoisChoisiSelect" + ClientConjoint]}
                  onChange={context.handleDepartRetraite(ClientConjoint, "DepartMoisChoisiSelect")}
                  xs
                  textAlign="center"
                />
              </Column>
              <Column style={{ alignSelf: "center" }}>mois</Column>
            </Row>
            <Row tpad>
              <Column>
                <StateBox
                  label="A la date du"
                  inline
                  value={context.state["DepartDateChoisi" + ClientConjoint]}
                  onClick={context.handleDepartRetraite(ClientConjoint, "DepartDateChoisi")}
                  normal
                />
              </Column>
              <Column>
                <TextField value="01" disabled xs textAlign="center" />
              </Column>
              <Column style={{ marginTop: "8px" }}>/</Column>
              <Column>
                <TextField
                  value={context.state["MoisDepartDateChoisiInput" + ClientConjoint]}
                  onChange={context.handleDepartRetraite(ClientConjoint, "MoisDepartDateChoisiInput")}
                  xs
                  textAlign="center"
                />
              </Column>
              <Column style={{ marginTop: "8px" }}>/</Column>
              <Column>
                <TextField
                  value={context.state["AnneeDepartDateChoisiInput" + ClientConjoint]}
                  onChange={context.handleDepartRetraite(ClientConjoint, "AnneeDepartDateChoisiInput")}
                  dataType="uint"
                  xs
                  textAlign="center"
                />
              </Column>
            </Row>
            <Row tpad>
              <Column>
                <StateBox
                  label="Au taux plein"
                  inline
                  value={context.state["DepartAgeTauxPlein" + ClientConjoint]}
                  onClick={context.handleDepartRetraite(ClientConjoint, "DepartAgeTauxPlein")}
                  normal
                />
              </Column>
            </Row>
            <Row  tpad>
              {/* <Column >
                <CheckBox
                  label="Application de la réforme des retraites 2023 (hors départ anticipé carrière longue)"
                  inline
                  checked={true} 
                  unchecked={false}
                  value={context.state["ReformeRetraite" + ClientConjoint]}
                  onChange={context.handleReformeRetraite(ClientConjoint, "ReformeRetraite"+ClientConjoint)}
                />
              </Column> */}
              <Column>
               <CheckBox
                label="Déblocage de l'âge d'ouverture des droits"
                inline
                checked={true}
                unchecked={false}
                value={context.state["UnlockAgeOuverture" + ClientConjoint]}
                onChange={context.handleCheckChange("UnlockAgeOuverture" + ClientConjoint)}
                />
                </Column>
                <Column style={{ paddingTop: "14px" }} lpad>
                <Hover style={{ width: "500px", textAlign: "justify", padding: "14px" }} icon={<FaInfo />}>
                Le logiciel intègre automatiquement les âges d’ouverture des droits propres à chaque régime. Nous vous déconseillons vivement de cocher la case sauf si vous souhaitez prévoir un départ anticipé au titre du handicap par exemple.
                </Hover>
              </Column>
            </Row>
          </Column>
        </CardBox>
      </Column>
      {/* <FormControlLabel label="A la date du" control={<Checkbox checked={context.state.DepartDateChoisi} onChange={context.handleDepartRetraite("DepartDateChoisi")} />} />
      <TextField value="01" disabled />
      <TextField value={context.state.MoisDepartDateChoisiInput} onChange={context.handleDepartRetraite("MoisDepartDateChoisiInput")}  />
      <TextField value={context.state.AnneeDepartDateChoisiInput} onChange={context.handleDepartRetraite("AnneeDepartDateChoisiInput")}  />
      <FormControlLabel label="Au taux plein" control={<Checkbox checked={context.state.DepartAgeTauxPlein} onChange={context.handleDepartRetraite("DepartAgeTauxPlein")} />} /> */}
    </>
  );
};
export default DateDepartRetraite;
